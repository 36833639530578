import store from '@store';
import services from '@services';

export default async () => {
  store.dispatch('setExportMaximoState', true);
  const { pid, name } = store.getters.currentProject;

  try {
    const res = await services.dashboard.getMetadataXlsx(pid);

    const href = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `${name}-output.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
    store.dispatch('setExportMaximoState', false);
    return res;
  } catch (err) {
    // Response data is type blob and needs to be parsed into JSON
    const data = JSON.parse(
      await err.response.data.text(),
    );
    store.dispatch('setExportMaximoState', false);
    store.dispatch('setNotification', {
      message: data.error.body.details.error_message,
      success: true,
      color: 'error',
    });

    return err;
  }
};

<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        fab
        dark
        icon
        v-bind="attrs"
        v-on="on"
        @click="exportMaximoReport"
      >
      <v-icon>fas fa-file-excel</v-icon>
    </v-btn>
    </template>
    <span>Export Output File</span>
  </v-tooltip>
</template>

<script>
import exportMaximoReport from '@components/projects/nypa/exportMaximoReport';

export default {
  name: 'MaximoReportButton',
  methods: {
    exportMaximoReport,
  },
};
</script>

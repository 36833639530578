import jwtDecode from 'jwt-decode';
import firebase from '@database/index';
import services from '@services';
import { encrypt, decrypt } from '@utils/crypto';
import { createCookie, readCookie, eraseCookie } from '@authentication/cookie';
import platformBackendClient, { setAuthToken } from '@backend-clients/platform-backend';
import { setMLAuthToken } from '@backend-clients/ml-backend';
import cypressAccess from '@utils/cypress-access';

const { auth } = firebase;
// const PHONE_AUTH_URL = 'https://twilio-server-246321.appspot.com/send-code';

const state = {
  // User data
  user: null,
  users: [],
  // Loaders
  loading: false,
  loadUsers: false,
  updating: false,
  // Email & Password Auth
  firstFactor: false,
  // TWO FACTOR AUTH
  sendingCode: false,
  verifyingTwoFactor: false,
  secondFactor: false,
  // FORGOT PASSWORD STATES
  codeSent: false,
  codeVerified: false,
  passwordResetted: false,
  codeGenerated: false,
  backdoor: false,
  errors: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    password2: '',
    country: '',
  },
};
/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const getters = {
  allUsers: (state) => state.users,
  // User data
  currentUser: (state) => state.user,
  isAdmin: (state) => state.user.permissions.includes('FULL_ACCESS'),
  isTeamLead: (state) => state.user.permissions.includes('USER_MANAGEMENT_TEAM'),
  // Loaders
  loadingUser: (state) => state.loading,
  loadingUsers: (state) => state.loadUsers,
  updatingUser: (state) => state.updating,
  // Authentication
  firstFactorAuthenticated: (state) => state.firstFactor,
  secondFactorAuthenticated: (state) => state.secondFactor,
  verifyingTwoFactor: (state) => state.verifyingTwoFactor,
  loggedIn: (state) => state.firstFactor && state.secondFactor,
  hasBackdoorAccess: (state) => state.backdoor,
  // Errors
  userError: (state) => state.errors,
  hasPermission: (state) => (permissionCode) => {
    if (state.user.permissions.includes('FULL_ACCESS')) {
      return true;
    }
    return state.user.permissions.includes(permissionCode);
  },
};

const actions = {
  getBackdoorAccess({ commit }, uid) {
    return new Promise((resolve, reject) => {
      platformBackendClient.get(`/api/auth/backdoor/access/${uid}`)
        .then((res) => {
          const { hasAccess } = res.data;

          commit('enable_backdoor', hasAccess);
          resolve(hasAccess);
        })
        .catch((err) => reject(err));
    });
  },
  setSecondFactorAuth({ commit }) {
    const test = readCookie('2fa');
    const authenticated = decrypt(test) === process.env.VUE_APP_2FA_VALIDATION;
    commit('set_two_factor', authenticated);
  },
  async verifyTwoFactorAuth({ commit }, payload) {
    commit('verifying_two_auth', true);
    const body = { code: payload.code, sid: payload.sid, cypress_bypass: cypressAccess() };

    try {
      const res = await platformBackendClient.post(`/api/auth/${payload.verification}/verify/${payload.uid}`, body);
      const { token } = res.data;

      // Sets token to localStorage
      // localStorage.setItem('jwtToken', token);
      const encryption = encrypt(process.env.VUE_APP_2FA_VALIDATION);
      createCookie('2fa', encryption);

      // Sets token to Auth header
      // setAuthToken();
      setMLAuthToken(token);
      const decoded = jwtDecode(token);

      const uRes = await services.users.get(decoded.user_id);
      const user = uRes.data;
      commit('fetch_user', user);
      const test = readCookie('2fa');
      const authenticated = decrypt(test) === process.env.VUE_APP_2FA_VALIDATION;
      commit('set_two_factor', authenticated);

      // Two Factor Auth finishes verifications
      commit('verifying_two_auth', false);
      return res;
    } catch (err) {
      commit('verifying_two_auth', false);
      const notification = { success: true, message: 'Your verification code did not match' };

      commit('set_notification', notification);
      setTimeout(() => {
        commit('set_notification', { message: '', success: false });
      }, 3000);
      return err;
    }
  },
  getUsers({ commit }, query) {
    commit('loading_users', true);

    return new Promise((resolve, reject) => {
      services.users.all(query)
        .then((res) => {
          commit('fetch_users', res.data);
          commit('loading_users', false);
          resolve(res);
        })
        .catch((err) => {
          commit('loading_users', false);
          reject(err);
        });
    });
  },
  setCurrentUser({ commit }, uid) {
    commit('loading_user');
    return new Promise((resolve, reject) => {
      services.users.firebase_get(uid)
        .then((res) => {
          commit('fetch_user', res.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  setFirstFactorAuth({ commit }, user) {
    const authenticated = 'currentUser' in auth();
    commit('set_first_factor', authenticated);
    commit('fetch_user', user);
  },
  login({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      auth().signInWithEmailAndPassword(credentials.email, credentials.password)
        .then(() => {
          // Search for user data
          services.users.firebase_get(auth().currentUser.uid)
            .then((res) => {
              const authenticated = 'currentUser' in auth();
              const user = res.data;
              const domain = window.location.hostname;
              const allowedDomains = user.allow_domain.replaceAll(' ', '').split(',');
              if (process.env.NODE_ENV !== 'development' && !allowedDomains.includes(domain)) {
                alert(`You are not allowed to access this domain. You will be redirected to ${user.allow_domain}`);
                window.location.replace(`https://${user.allow_domain}`);
                auth().signOut();
                reject(new Error('Domain restriction'));
              }
              commit('set_first_factor', authenticated);
              commit('fetch_user', user);
              resolve(res);
            })
            .catch((err) => reject(err));
        }).catch((err) => reject(err));
    });
  },
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      auth().signOut()
        .then(() => {
          commit('remove_user', null);
          // If localstorage has a buzzToken, remove it since it's the old token
          if (localStorage.getItem('buzzToken')) localStorage.removeItem('buzzToken');
          if (readCookie('2fa')) eraseCookie('2fa');
          if (localStorage.getItem('sid')) localStorage.removeItem('sid');

          setAuthToken(false);
          commit('set_first_factor', false);
          commit('set_two_factor', false);
          resolve(200);
        })
        .catch((err) => reject(err));
    });
  },
  // Updates the user document in Firestore
  updateUser({ commit }, payload) {
    commit('updating_user', true);
    return new Promise((resolve, reject) => {
      services.users.update(payload.user_id, payload.uid, payload.updates)
        .then((res) => {
          commit('update_user', res.data);
          commit('updating_user', false);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  // Updates the user data in Firebase.Auth
  updateUserAuth({ commit }, profile) {
    commit('test');
    const {
      firstName,
      lastName,
      phone,
      email,
    } = profile;
    return new Promise((resolve, reject) => {
      const updatedProfile = { displayName: `${firstName} ${lastName}`, email, phoneNumber: phone };
      firebase.auth().currentUser.updateProfile(updatedProfile)
        .then(() => {
          resolve({ msg: 'Update Success' });
        })
        .catch((err) => reject(err));
    });
  },
  // Resets Password by email
  passwordReset({ commit }, emailAddress) {
    return new Promise((resolve, reject) => {
      auth().sendPasswordResetEmail(emailAddress)
        .then(() => {
          const status = {
            code: 'auth/user-found',
            message: 'Please check your inbox for instructions on resetting your password.',
          };
          commit('reset_password', status);
          resolve(status);
        })
        .catch((err) => reject(err));
    });
  },
  createUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      services.users.create(payload.company_id, payload.cid, payload.data)
        .then((res) => {
          commit('create_user', res.data);
          resolve(res);
        })
        .catch((err) => {
          commit('user_error', err);
          reject(err);
        });
    });
  },
  deleteUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      services.users.delete(payload.cid, payload.company_id, payload.user_id, payload.uid)
        .then((res) => {
          commit('delete_user', res.data.uid);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  sendNewUserEmail({ commit }, userData) {
    const { adminStatus, credentials } = userData;
    return new Promise((resolve, reject) => {
      if (!adminStatus) {
        const error = new Error('You are not an admin');
        reject(error);
      }

      platformBackendClient.post(`/api/users/email/${credentials.uid}`,
        credentials)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          commit('user_error', err);
          reject(err);
        });
    });
  },
  // FORGOT PASSWORD ACTIONS
  getUserByEmail({ commit }, email) {
    return new Promise((resolve, reject) => {
      services.users.email_get({ email })
        .then((res) => {
          commit('fetch_user', res.data);
          resolve(res);
        })
        .catch((err) => {
          commit('user_error', { code: 'userNotFound', message: 'No user found with that email address' });
          reject(err);
        });
    });
  },
  getUserDocumentById({ commit }, uid) {
    commit('loading_user', true);
    return new Promise((resolve, reject) => {
      services.users.get(uid)
        .then((res) => {
          commit('fetch_user', res.data);
          resolve(res);
        })
        .catch((err) => {
          commit('loading_user', false);
          commit('user_error', { code: 'userNotFound', message: 'No user found with that email address' });
          reject(err);
        });
    });
  },
  resetPassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      services.auth.reset_password(payload)
        .then((res) => {
          commit('password_reset', true);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  updateJWTToken({ commit }, uid) {
    commit('updating_user', true);
    return new Promise((resolve, reject) => {
      platformBackendClient.put(`/api/auth/signed-token/update/${uid}`)
        .then((res) => {
          const { token } = res.data;
          createCookie('2fa', encrypt(process.env.VUE_APP_2FA_VALIDATION));
          // localStorage.setItem('jwtToken', token);

          setMLAuthToken(token);
          const decoded = jwtDecode(token);
          commit('updating_user', false);
          resolve(decoded);
        })
        .catch((err) => reject(err));
    });
  },
  createUserSSO({ commit }, payload) {
    return new Promise((resolve, reject) => {
      services.users.sso_create(payload)
        .then((res) => {
          commit('fetch_user', res.data);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  createUserDocument({ commit }, payload) {
    return new Promise((resolve, reject) => {
      services.users.create(payload)
        .then((res) => {
          commit('fetch_user', res.data);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  setUserError({ commit }, errors) {
    commit('user_error', errors);
  },
  updateUserStatus({ commit }, payload) {
    commit('update_user_status', payload);
  },
};

const mutations = {
  enable_backdoor: (state, access) => { state.backdoor = access; },
  // First Factor Authentication
  set_first_factor: (state, authenticated) => { state.firstFactor = authenticated; },

  // Two Factor Authentication
  set_two_factor: (state, authenticated) => { state.secondFactor = authenticated; },
  verifying_two_auth: (state, verifying) => { state.verifying = verifying; },

  // Loading Mutation
  loading_user: (state, loading) => {
    state.loading = loading;
  },
  loading_users: (state, loading) => {
    state.loadUsers = loading;
  },
  updating_user: (state, updating) => { state.updating = updating; },

  finish_loading_verification: (state) => { state.loadVerification = false; },

  // Errors
  user_error: (state, err) => {
    if (err.firstName) state.errors.firstName = err.firstName;
    else state.errors.firstName = '';

    if (err.lastName) state.errors.lastName = err.lastName;
    else state.errors.lastName = '';

    if (err.email) state.errors.email = err.email;
    else state.errors.email = '';

    if (err.phone) state.errors.phone = err.phone;
    else state.errors.phone = '';

    if (err.password) state.errors.password = err.password;
    else state.errors.password = '';

    if (err.password2) state.errors.password2 = err.password2;
    else state.errors.password2 = '';

    state.errors.country = err.country || '';
  },
  reset_password: (state, status) => { state.status = status; },
  // CRUD
  fetch_users: (state, users) => {
    state.users = users;
  },
  fetch_user: (state, user) => {
    state.user = user;
    state.loading = false;
  },
  update_user_status: (state, { uid, status }) => {
    state.users = state.users.map((user) => (user.uid === uid ? { ...user, status } : user));
  },
  remove_user: (state) => {
    state.user = null;
    state.secondFactor = false;
    state.firstFactor = false;
  },
  update_user: (state, user) => { state.user = user; },
  create_user: (state, newUser) => {
    state.users.unshift(newUser);
  },
  delete_user: (state, uid) => {
    state.users = state.users.filter((user) => user.uid !== uid);
  },

  // FORGOT PASSWORD MUTATIONS
  send_verification: (state, sent) => { state.codeSent = sent; },
  code_verified: (state, verified) => { state.codeVerified = verified; },
  password_reset: (state, resetted) => { state.passwordResetted = resetted; },
  verfication_code_generated: (state, generated) => { state.codeGenerated = generated; },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div class="project">
    <LoadingOverlay type="Maximo Report" :loading="exportingMaximoData"/>
    <Loading v-if="loadingProject" />
    <v-container v-else fluid>
      <h1>Project Description: </h1>
      <v-row>
        <v-col md="9">
          <v-card>
            <!-- NYPA Media Hub -->
            <div v-if="companyHas('mission_metadata')">
              <DataTable
                :loading="loadingProject"
                :entries="projectEntries"
                :expand="true"
                title="Project Details"
              >
                <template v-slot:actions>
                  <MaximoReportButton />
                </template>
              </DataTable>
              <DataTable
                :loading="loadingMission"
                :entries="missionEntries"
                title="Mission Details"
                :expand="true"
              >
                <template v-slot:actions>
                    <div class="d-flex flex-column p-2">
                      <div class="float-right d-block">
                          <span class="text-sm-caption">Status: </span>
                          <Loading v-if="loadingMissionStatus"/>
                          <v-chip
                            v-else
                            text-color="black"
                            color="#FAFAFA"
                            x-small
                          >
                            {{ upperFirst(currentMissionStatus.status) }}
                          </v-chip>
                      </div>
                      <div class="float-right d-block">
                        <span class="text-sm-caption">Last Updated: </span>
                        <Loading v-if="loadingMissionStatus"/>
                        <span class="text-sm-caption" v-else>
                          {{
                            currentMissionStatus.updated_at === 'Invalid'
                            ? currentMissionStatus.updated_at
                            : formatDate(
                                currentMissionStatus.updated_at,
                                'DATE_TIME_AMPM',
                              )
                          }}
                        </span>
                      </div>
                    </div>
                </template>
              </DataTable>
            </div>
            <!-- Original Project Overview table -->
            <ProjectDataTable :project="currentProject" v-else/>
          </v-card>
        </v-col>
        <v-col md="3">
          <div class="my-2">
            <v-btn id="back-to-projects"
                   color="primary"
                   to="/projects/view/grid" small>Back To Projects</v-btn>
          </div>
          <div class="my-2">
            <v-btn
              color="primary"
              :to="`/project/${currentProject.pid}/edit`"
              small
              id="edit-project"
              v-if="hasPermission('PROJECT_WRITE')"
            >
              Edit Project
            </v-btn>
          </div>
          <div class="my">
            <RetrainModelButton />
          </div>
          <div class="my-2">
            <v-btn
              color="primary"
              small
              id="image-ai-processing-btn"
              @click="goToImageRoute"
            >
              Images & AI Processing
            </v-btn>
          </div>
          <div class="my-2" v-if="companyHas('mission_metadata') && companyHas('asset_metadata')">
              <V-btn
                @click="aiProcessingForMediaHub"
                color="primary"
                :disabled="!(this.currentMission)"
                small
                >
                AI processing for Media Hub
              </V-btn>
          </div>

          <div class="my-2"
               v-if="companyHas('mission_metadata') && companyHas('asset_metadata')">
            <V-btn @click="exportToMediaHub" color="primary" small>
             Export to Media Hub
            </V-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar" :timeout="3000">
      {{snackbarMessage}}
    </v-snackbar>
    <loading-overlay  :type="loadingOverlayType" :override="true" :loading="shouldShowOverlay" />
    <v-overlay :value="loadingImages">
      <div style="text-align: center">
        <div>
          <v-progress-circular
            :size="70"
            :width="7"
            color="white"
            indeterminate
          ></v-progress-circular>
        </div>
        <div>
          Loading images...
        </div>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import upperFirst from 'lodash/upperFirst';
import formatDate from '@utils/time-formats';
import Loading from '@components/common/Loading.vue';
import ProjectDataTable from '@components/projects/ProjectDataTable.vue';
import RetrainModelButton from '@components/projects/RetrainModelButton.vue';
import DataTable from '@components/common/DataTable.vue';
import MaximoReportButton from '@components/projects/nypa/MaximoReportButton.vue';
import LoadingOverlay from '@components/common/loaders/LoadingOverlay.vue';
import missions from '@services/missions';

export default {
  name: 'Project',
  components: {
    Loading,
    ProjectDataTable,
    RetrainModelButton,
    DataTable,
    MaximoReportButton,
    LoadingOverlay,
  },
  props: ['pid'],
  data: () => ({
    snackbar: false,
    snackbarMessage: '',
    loadingOverlayType: '',
    shouldShowOverlay: false,
    imageList: [],
    loadingImages: false,
    projectFields: ['name', 'description', 'date', 'location', 'teams', 'first_reviewer', 'project_type'],
  }),
  methods: {
    ...mapActions(['getProject', 'getImagesByProject', 'getMission', 'getStatus']),
    upperFirst,
    formatDate,
    async fetchProject() {
      await this.getProject({ pid: this.pid, cid: this.currentCompany.cid });
    },
    async goToImageRoute() {
      this.loadingImages = true;
      const { pid, cid } = this.currentProject;
      const { data } = await this.getImagesByProject({ cid, pid });
      const imgURL = `/project/${this.currentProject.pid}/images/original/${(data.length > 0) ? data[0].filename : ''}`;
      await this.$router.push(imgURL);
      this.loadingImages = false;
    },
    async exportToMediaHub() {
      this.shouldShowOverlay = true;
      this.loadingOverlayType = 'Triggering export ai processing to Media Hub';
      await missions.pushToMediaHub(this.currentProject.pid);
      this.snackbar = true;
      this.snackbarMessage = 'triggered export AI processing to media hub Successfully.';
      this.shouldShowOverlay = false;
    },
    async aiProcessingForMediaHub() {
      this.shouldShowOverlay = true;
      this.loadingOverlayType = 'triggering AI processing for media hub';
      await missions.triggerAiProcessing(this.currentProject.pid, this.currentMission.id);
      this.snackbar = true;
      this.snackbarMessage = 'triggered AI processing for media hub Successfully.';
      this.shouldShowOverlay = false;
    },
    getPopoverButtonText() {
      const buttonText = `View ${this.currentMission.name}'s status`;
      return (buttonText.length > 20) ? 'View Status' : buttonText;
    },
  },
  computed: {
    ...mapGetters(['loadingProject', 'hasPermission', 'currentProject', 'currentCompany', 'loadingMission', 'currentMission', 'companyHas', 'companyConfig', 'exportingMaximoData', 'loadingMissionStatus', 'currentMissionStatus', 'missionStatusTable']),
    missionEntries() {
      const dateFields = ['start_datetime', 'end_datetime', 'date'];
      // this currentMission could be null
      if (!this.currentMission) return [];
      const fields = Object.keys(this.currentMission).filter((field) => !field.split('_').includes('id'));
      const rows = fields.map((field) => {
        const shouldExpand = this.currentMission[field] instanceof Object;
        const name = (field === 'name') ? `Mission ${field}` : upperFirst(field.split('_').join(' '));
        const row = {
          key: field,
          name,
        };

        // Include an 'expandedItems' array of entries if there are child
        // entries to display
        if (shouldExpand) {
          row.expandable = shouldExpand;
          row.value = `Expand to view more information on ${name}`;
          const missionMetadataAliases = JSON.parse(this.companyConfig('mission_metadata'));
          row.expandedItems = [];

          row.expandedItems = Object.keys(this.currentMission[field])
            // Only show data that's included in the company feature configs
            .filter((key) => Object.keys(missionMetadataAliases).includes(key))
            .map((key) => {
              // Map the keys to the alias names
              const k = missionMetadataAliases[key];
              const isObject = (value) => typeof value === 'object' && value !== null;
              const value = (isObject(this.currentMission[field][key]))
                ? JSON.stringify(this.currentMission[field][key])
                : this.currentMission[field][key];
              return {
                name: upperFirst(k.split('_').join(' ')),
                value,
              };
            });
        } else {
          row.value = (dateFields.includes(field)) ? formatDate(this.currentMission[field], 'YEAR_MONTH_DATE') : this.currentMission[field];
        }
        return row;
      });

      return rows;
    },
    projectEntries() {
      const rows = this.projectFields.map((field) => {
        const name = (field === 'name') ? `Project ${field}` : upperFirst(field.split('_').join(' '));
        let value;
        if (field === 'date') {
          value = formatDate(this.currentProject[field], 'YEAR_MONTH_DATE');
        } else if (field === 'teams') {
          value = this.currentProject[field].map((team) => team.name).join(', ');
        } else if (field === 'first_reviewer') {
          value = (this.currentProject[field]) ? this.currentProject[field].name : 'No reviewer assigned';
        } else if (field === 'project_type') {
          value = (this.currentProject[field]) ? this.currentProject[field] : 'This project has not been assigned a project type yet';
        } else {
          value = this.currentProject[field];
        }

        return {
          key: field,
          name,
          value,
        };
      });

      return rows;
    },
    statusColor() {
      return (this.currentMissionStatus.status === 'exported')
        ? { text: 'black', background: '#66BB6A' }
        : { text: 'black', background: '#FFEE58' };
    },
  },
  created() {
    this.fetchProject();
    this.getMission(this.pid);
  },
  watch: {
    pid: 'fetchProject',
    currentMission() {
      this.getStatus({ projectId: this.pid, missionId: this.currentMission.id });
    },
  },
};
</script>
